import Home from "../../../components/classic/home"

function GuestLayout({ children }: any) {

    return <>
        <section className="login">
            <div className="main">
                <div className="overlay">
                    <div className="content">
                        <div className="row m-0">
                            <div className="col-8 mb-p0">
                                <Home />
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
}

export default GuestLayout