import { Dispatch } from "redux";
import { postRequest, getRequest } from "../utils/http";
import * as T from "../actions/Types";

const api_domian = process.env.REACT_APP_API_HOST as string;

/** 
 * Remove employee from room by roomid: params- RoomGuid and EmpGuid */
export const leaveRoomById = (params: any) => async (dispatch: Dispatch) => {
    try {
        let sucMsg = params.get('no_toast')?'':'';
        sucMsg = params.get('IsScreenShared')?'RemoveScreenshare': sucMsg;
        console.log("SSS",sucMsg);
       // dispatch({ type: T.ADD_LOADING });
        postRequest(`${api_domian}/employee/RemoveEmployeeFromGroupSession`, params)
       .then((resp: any)=>{
            dispatch({
                type: T.SAVE_SUCCESS,                
                success: sucMsg
            });           
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,                
                success: sucMsg,
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

/** 
* Remove employee from all rooms: params- EmpGuid */
export const leaveFromAllRooms = (params: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(params);
        dispatch({ type: T.ADD_LOADING });
        postRequest(`${api_domian}:/employee/RemoveEmployeeFromAllRooms`, params)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "RemovedAllEMP"
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

/** 
* Add employee room with params- RoomGuid, EmpGuid */
export const AddEmployeeToRoom = (params: any,isShared:boolean = false) => async (dispatch: Dispatch) => {
    try {
        console.log("AddEmployeeToRoom ");
        dispatch({ type: T.ADD_LOADING });
        postRequest(`${api_domian}/employee/AddEmployeeToGroupSession`, params)
            .then((resp: any) => {
                console.log("AddEmployeeToRoom ",isShared);
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: isShared ? "AddScreenToRoom" : "AddEmployeeToRoom"
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

/** 
* Add employee room with params- RoomGuid, externalUserName */
export const AddGuestToRoom = (params: any,isShared:boolean = false) => async (dispatch: Dispatch) => {
    postRequest(`${api_domian}/employee/AddExternalUserToGroupSession`, params)
        .then((resp: any) => {
            dispatch({
                type: T.SAVE_SUCCESS,
                success: isShared ? "AddScreenToRoom" : "AddEmployeeToRoom"
            });
        }).catch(e => {
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
}
/** 
* Add employee room with params- RoomGuid, EmpGuid */
export const RemoveGuestFromRoom = (params: any) => async (dispatch: Dispatch) => {
    let sucMsg = params.get('no_toast')?'':'RemoveEmployeeFromRoom';
        sucMsg = params.get('IsScreenShared')?'RemoveScreenshare': sucMsg;
        console.log("SS",sucMsg);
    postRequest(`${api_domian}/employee/RemoveExternalFromGroupSession`, params)
        .then((resp: any) => {
            if(sucMsg == 'RemoveEmployeeFromRoom')
                return; 
            dispatch({
                type: T.SAVE_SUCCESS,
                success: sucMsg
            });
        }).catch(e => {
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
}
/** form: RoomGuid, EmpGuid*/
export const UpdateGuestLastActivity = async (params: any) => {
    try {
        return postRequest(`${api_domian}/employee/UpdateExternalUserGroupSessionLastActivityTime`, params)
            .then((resp: any) => {
                return resp;
            }).catch(e => {
                return e;
            });
    } catch (e) {
        return e;
    }
}
/** 
* check Room Status Instantly */
export const getGroupLockStatus = async (roomId: string) => {
    try {
        return getRequest(`${api_domian}/Employee/GetGroupLockStatus?groupGuid=${roomId}`)
            .then((resp: any) => {                
                return resp.result;
            }).catch(e => {
                console.log(e);
                return "ERROR";
            });
    } catch (e) {
        return "ERROR";
    }
}

export const getEventDetailsByMeetingId = async (meetingId: string) => {
    try {
        return getRequest(`${api_domian}/Teams/GetEventsListByMeetingId?MeetingId=${meetingId}`)
            .then((resp: any) => {
                return resp;
            }).catch(e => {
                console.log(e);
                return [];
            });
    } catch (e) {
        return [];
    }
}
/** 
* Add employee room with params- RoomGuid, EmpGuid */
export const updateLock = (params: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(params);
        dispatch({ type: T.ADD_LOADING });
        postRequest(`${api_domian}/employee/LockUnlockGroup`, params)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "UpdateRoomLock"
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

/*** GetGroupSessionDetails by groupGuid*/
export const GetRoomSessionDetails = (groupGuid: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(params);
       // dispatch({ type: T.ADD_LOADING });
        getRequest(encodeURI(`${api_domian}/employee/GetGroupSessionDetails?groupGuid=${groupGuid}`))
            .then((resp: any) => {
                dispatch({
                    type: T.ROOM_STATS,
                    payload: { [groupGuid]: resp }
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}
/** form: RoomGuid, EmpGuid*/
export const UpdateLastActivityTime = async (params: any) => {
    try {
        return postRequest(`${api_domian}/employee/UpdateGroupSessionLastActivityTime`, params)
            .then((resp: any) => {
                return resp;
            }).catch(e => {
                return e;
            });
    } catch (e) {
        return e;
    }
}
/**toEmail,meetingLink as form params */
export const SendMeetingInvite = (params: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(params);
        dispatch({ type: T.ADD_LOADING });
        postRequest( encodeURI(`${api_domian}/employee/SendMeetingInvite`), params)
        //postRequest(`${domain}:${port}/api/employee/SendMeetingInvite`, params)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "SentMeetingLink"
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const updateRoomSession = (params: any) => async (dispatch: Dispatch) => {
    try {
        
        getRequest( encodeURI(`${api_domian}/employee/LeaveRoomByAGid?AgId=${params}`))
            .then((resp: any) => {
               
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}
export const getEmployee = async (empGuid: string) => {
    try {
        return getRequest(`${api_domian}/Employee/GetEmployees?EmpGuid=${empGuid}`)
            .then((resp: any) => {
                return resp.result;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const getRooms = async (empGuid: string) => {
    try {
        return getRequest(`${api_domian}/Meeting/GetGroupsListByEmp?empGuid=${empGuid}`)
            .then((resp: any) => {
                return resp.result;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

