import Header from "../../../components/classic/header"
import Menus from "../../../components/classic/menus"

function PrivateLayout({ children }: any) {

   const windowPath = window.location.pathname;

    return <>
        <section className={windowPath.slice(1,windowPath.length)}>
            <div className="main inner-bg">
                <div className="overlay">
                    <Header />
                    <div className="content-inner">
                        <div className="body-content">
                            <Menus/>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default (PrivateLayout)