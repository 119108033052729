import { ReactNode, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";

interface ContainerProps {
    children: ReactNode;
    title:string;
    // className?: string;
    // style?: React.CSSProperties;
  }
const Card = (props : ContainerProps) => {

    const [toggleCard, setToggleCard] = useState(false);

    const onToggle = () =>{
        setToggleCard(!toggleCard)
    }

    return (
        <div className={(toggleCard ? 'b-hide ' : ' ')+ 'gradientBox fs-14'}>
        <div className="Grheader">
            <h6 className="text-white mb-0">{props.title}<span className="float-right hand" onClick={onToggle}>
                {/* <img src={minusimg} /> */}
                <AiOutlineMinus className="minus"/>
                 <AiOutlinePlus className="plus"/> 
                </span></h6>
        </div>
        <div className="body">
            {props.children}           
        </div>
        </div>
    )
  }

  export default  Card;