import React from 'react';
import { tt as t } from '../../../i18n';
import logo from '../../../assets/images/sute-logo-white.png'
import styles from '../home/home.module.scss'
import signstyles from '../../../pages/classic/signin/signin.module.scss'
import { useSelector } from 'react-redux';
import { StoreClass } from '../../../store/Store';
import { useNavigate } from 'react-router-dom';

function Home(props: any | undefined) {

    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

    const thanks = `Thank you for submitting your info!##We’ll review and confirm your profile, and you’ll soon receive an email with your login credentials.##
        Once received, you’ll be ready to start exploring SUTE.## As a quick reminder:##
        - You’re part of our exclusive pilot experience—expect to see ongoing updates as we shape SUTE based on your feedback.##
- ⁠We encourage you to test out the virtual assistant, our video conferencing tools, and other live features.##
                - ⁠Keep an eye out for areas marked “coming soon” as we continue to roll out new functionality each month.##
        Thank you for being part of our journey!`

    let { msg } = props
    const welcomeSute = msg == '##THANKS##' ? thanks.split('##') : t('welcome-sute').split('##');
    msg = (msg == '##THANKS##') ? undefined : msg
    const wel = msg ||  welcomeSute[0]
    const navigate = useNavigate();
    //const isDone = wel == '##THANKS##' ? true : false;
    return (<>
        <div className={styles.welcome}>
            <div className={`${styles.logo}`} >
                <img src={logo} className='hand' onClick={() => { navigate('/') }} />
            </div>

            <h4 className='mb-4'>{wel}</h4>
            {(welcomeSute || []).map((obj: any, index: number) => {
                if (index == 0)
                    return
                return (<p>{obj}</p>)
            })}
            {/* <button className="btn know-more">{t('knowmore')}</button> */}
        </div>
    </>)
}
export default Home;