import { useNavigate } from "react-router-dom";
import profileimg from "../../../assets/images/profile-img.png"
import { tt as t } from '../../../i18n';
import { FaAngleRight } from "react-icons/fa6";

function Employee(props: any) {

    const { emp } = props;
    const navigate = useNavigate();

    return (<>
        <div className="user-info-section">
            <div className="user-img">
                <img src={emp.empData.ProfileImageURL ? emp.empData.ProfileImageURL : '/assets/media/svg/profile/profile_picture.PNG'} />
            </div>
            <div className="user-info-text">
                <div className="col-12 row m-0 p-0">
                    <div className="col-11 p-0">
                        <h6>{emp.empData.FirstName + ' ' + emp.empData.LastName}</h6>
                        <p>{t('profile.id')}: {emp.empData.EmployeeId}</p>
                    </div>
                    <div className="col-1 p-0 m-auto hand" onClick={()=>{navigate('/my-profile')}}>
                        <FaAngleRight className="mr-0 fs-20" />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Employee